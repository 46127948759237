<script>
import distinctColors from 'distinct-colors';
import Vuex from 'vuex';
// import moment from 'moment';
import dayjs from 'dayjs';

export default {
  props: [],
  data: () => ({
    today: new Date().toISOString().split('T')[0],
    start: null,
    end: null,
    focus: '',
    type: 'month',
  }),
  computed: {
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC',
        month: 'long',
      });
    },
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return '';
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? '' : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? '' : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case 'month':
          return `${startMonth} ${startYear}`;
        case 'week':
        case '4day':
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case 'day':
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return '';
    },

    ...Vuex.mapState([
      'focusDay',
      'scopes',
      'scopeId',
      'scheduledProjectKeys',
      'scopesByMeetingDateRange',
      'scopesByUploadDateRange',
    ]),
    availableProjects() {
      return [...this.$store.getters.projectsViewable].sort(
        this.dynamicSort('projectId')
      );
    },
    scopesByRange() {
      const scopeToEvent = type => {
        return scope => {
          const firstFocus = Object.keys(scope.focus || {})[0];
          const name = firstFocus.type || 'Analysis';
          const summary = scope.summary;
          let start = this.formatDate(scope.meetingDate.toDate());
          let end;
          const projectId = scope.project.key;
          const number = scope.number;
          const color = this.availableProjects.findIndex(
            p => p.projectId === scope.project.key
          );
          const id = scope.id;

          if (type === 'analysis') {
            if (!scope.uploadDate) return;
            start = this.formatDate(scope.uploadDate.toDate());
            end = this.formatDate(scope.meetingDate.toDate().addDays(-1));
          }

          return {
            name,
            id,
            summary,
            start,
            end,
            projectId,
            type,
            number,
            color,
          };
        };
      };

      const meetingEvents = this.scopesByMeetingDateRange.map(
        scopeToEvent('workshop')
      );
      const meetingUploadEvents = this.scopesByMeetingDateRange.map(
        scopeToEvent('analysis')
      );
      const uploadEvents = this.scopesByUploadDateRange.map(
        scopeToEvent('analysis')
      );

      const uploadScopes = new Map();
      [...uploadEvents, ...meetingUploadEvents].forEach(
        event => event && event.id && uploadScopes.set(event.id, event)
      );

      return [...meetingEvents, ...uploadScopes.values()]
        .filter(
          event =>
            event.id && this.scheduledProjectKeys.includes(event.projectId)
        )
        .sort((a, b) => {
          const A = a.start,
            B = b.start;
          return A === B ? 0 : A < B ? -1 : 1;
        });
    },
    colors() {
      return [
        ...distinctColors({
          count: this.$store.getters.projectsViewable.length,
          lightMin: 15,
          lightMax: 50,
          chromaMin: 25,
        }),
      ];
    },
  },
  watch: {
    scheduledProjectKeys: function (newKeys) {
      // let calendar = this.$refs.calendar;

      const date = new Date(this.focusDay);
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      if (!Date.addDays) {
        Date.prototype.addDays = function (days) {
          const date = new Date(this.valueOf());
          date.setDate(date.getDate() + days);
          return date;
        };
      }

      const start = firstDay.addDays(-7);
      const end = lastDay.addDays(7);

      const range = { start, end };

      if (newKeys && newKeys.length > 0) {
        this.$store.dispatch('updateAvailableEvents', {
          range,
        });
      }
    },
  },
  mounted() {
    if (!this.focusDay) this.$store.commit('setFocus', this.today);
    this.$refs.calendar.checkChange();
  },
  methods: {
    showEvent({ event }) {
      this.$router.push({
        name: 'scope',
        params: { projectId: event.projectId, scopeId: event.number },
      });
    },
    setToday() {
      this.$store.commit('setFocus', this.today);
    },
    prev() {
      // let currentFocusDate = new moment(this.focusDay);
      const currentFocusDate = dayjs(this.focusDay);
      const newFocus = currentFocusDate
        .subtract(1, 'month')
        .format('YYYY-MM-DD');

      this.$store.commit('setFocus', newFocus);
    },
    next() {
      // let currentFocusDate = new moment(this.focusDay);
      const currentFocusDate = dayjs(this.focusDay);
      const newFocus = currentFocusDate.add(1, 'month').format('YYYY-MM-DD');

      this.$store.commit('setFocus', newFocus);
    },
    nth(d) {
      return d > 3 && d < 21
        ? 'th'
        : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    dynamicSort(property) {
      let sortOrder = 1;
      if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
      }

      return function (a, b) {
        if (sortOrder === -1) {
          return b[property].localeCompare(a[property]);
        } else {
          return a[property].localeCompare(b[property]);
        }
      };
    },
    updateRange({ start, end }) {
      if (!Date.addDays) {
        Date.prototype.addDays = function (days) {
          const date = new Date(this.valueOf());
          date.setDate(date.getDate() + days);
          return date;
        };
      }

      const visibleStart = new Date(`${start.date}T00:00:00`);
      const visibleEnd = new Date(`${end.date}T00:00:00`);

      const range = {
        start: visibleStart.addDays(-7),
        end: visibleEnd.addDays(7),
      };

      this.start = start;
      this.end = end;

      this.$store.dispatch('updateAvailableEvents', { range });
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${
            a.getMonth() + 1
          }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
    getEventColor(event) {
      return this.colorSwatch(event.color, event.type === 'workshop');
    },
    getEventText(event) {
      return event.type !== 'workshop'
        ? this.colorSwatch(event.color, event.type !== 'workshop')
        : 'white';
    },
    colorSwatch(index, brighten) {
      brighten = Boolean(brighten);
      const color = this.colors[index];

      return (
        !brighten ? color.luminance(0.5) : color.darken().brighten().saturate()
      ).hex();
    },
  },
};
</script>

<template>
  <div class="px-3">
    <v-row>
      <v-col>
        <v-sheet height="calc(100vh - 250px)">
          <v-toolbar flat color="white" class="pt-1">
            <v-toolbar-title class="text-h6">{{ title }}</v-toolbar-title>
            <v-spacer />
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn text class="mr-4" color="grey darken-2" @click="setToday"
              >Today</v-btn
            >
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </v-toolbar>
          <v-calendar
            ref="calendar"
            v-model="focusDay"
            :weekdays="[1, 2, 3, 4, 5]"
            :events="scopesByRange"
            :event-color="getEventColor"
            :event-text-color="getEventText"
            min-weeks="5"
            type="month"
            @click:event="showEvent"
            @change="updateRange"
          >
            <template #event="{ event }">
              <div :class="event.type" class="pl-1">
                ({{
                  `${event.type === 'workshop' ? 'Workshop' : event.name}) ${
                    event.projectId
                  }-${event.number} ${
                    event.type === 'workshop' ? '' : '– ' + event.summary
                  }`
                }}
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.v-calendar {
  min-height: 450px;
  max-height: 750px;
}

.v-toolbar >>> .v-toolbar__content {
  padding-left: 0;
}
</style>
