<script>
import AnalysisCalendar from '../components/AnalysisCalendar.vue';

export default {
  name: 'ShowCalendar',
  components: {
    AnalysisCalendar,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
    };
  },
};
</script>

<template>
  <div>
    <AnalysisCalendar :project-id="projectId" />
  </div>
</template>
